.schedule-wrapper {
  width: 100%;
}

.schedule {
  height: 100%;
  scrollbar-gutter: stable;
}

.schedule-row {
  width: 100%;
  display: flex;
  align-items: stretch;
  position: relative;
}

.schedule-row-header {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  z-index: 10;
  background-color: white;
  height: inherit;
  position: sticky;
  left: 0;
}

.schedule-row-header-placeholder {
  height: 100%;
  flex-shrink: 0;
  display: none;
}

.schedule-row-items-wrapper {
  position: relative;
  flex-grow: 1;
  display: flex;
}

.schedule-item {
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
}

.schedule-header {
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 20;
  .schedule-header-label {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    .schedule-header-label-default {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      height: 100%;
    }
  }
}
