@import '@material-symbols/font-400';

.material-symbols-outlined-old {
  font-variation-settings: 'FILL' 0, 'wght' 600, 'GRAD' 0, 'opsz' 48;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}


:root {
  --angle: 45deg;
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

.animated-border-wrapper{
  padding: 2px;
  borderRadius: 10px;
  background-clip: padding-box, border-box;
  background: conic-gradient(
    from var(--angle),
    rgba(255, 255, 255, 0 ) 0%,
    rgba(255, 255, 255, 0 ) 40%,
    rgba(252,196,25,1) 50%,
    rgba(252,196,25,0) 51%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: rotate 3s linear infinite;
}
