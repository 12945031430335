.SegmentedControl{
  border: 1px solid #999999;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  align-items: stretch;
  button: {
    all: unset
  }

  .SegmentedControlItem{
    border-width: 0;
    background-color: transparent;
    color: #999999;
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    flex: 1 0;
    cursor: pointer;
    overflow: hidden;

    &[data-state='on'] {
      color: #FFF;
      background: linear-gradient(#136DFB, #0351CC);
    }
    &:hover{
      background-color: #136DFB11;
    }
  }
  &.variant-pill{
    border-radius: 50px;
    border-style: none;
    .SegmentedControlItem{
      background-color: #F2F7FF;
      padding: 10px 24px;
    }
  }
}
