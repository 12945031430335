#weather-briefing-modal{
  .mantine-Modal-content{
    overflow-y: hidden
  }
}

.maplibregl-popup-close-button{
  font-size: 30px;
  top: 5px;
  right: 5px;
}

.soar-resultmap-popup{
  .maplibregl-popup-content{
    padding: 42px 32px 24px 15px;
    border-radius: 12px;
    box-shadow: 4px 4px 6px #33333355;
    position: relative;
    height: 100%;
    .maplibregl-popup-close-button{
      color: #444;
      top: 15px;
      right: 12px;
      padding: 0;
    }
    @media screen and (min-width: 48em){
      padding: 28px 24px;
      .maplibregl-popup-close-button{
        top: 19px;
        right: 10px;
      }
    }
  }
  .maplibregl-popup-tip{
    display: none;
  }
}
